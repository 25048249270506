import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './headers/header.component';
import { UserHeadersComponent } from './headers/user-headers.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { DashFooterComponent } from './footer/dash-footer.component';
import { ErrorComponent } from './error-component/error.component';
import { FormComponent } from './forms/form.component';
import { CarouselModule } from 'primeng/carousel';
import { DialogService, MessageService } from 'primeng/api';
import { AutocompleteComponent } from './google-places.component';
import { GoogleLocationSelectorComponent } from './google-location-selector/google-location-selector.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { ErrorHandlerService } from '../services/error-handler.service';
import { AuthGuard } from '../guards/auth_guards';
import { PublicGuard } from '../guards/public_guards';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AgmCoreModule } from '@agm/core';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { RatingModule } from 'ng-starrating';
import { NgxPermissionsModule } from 'ngx-permissions';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { ToastModule } from 'primeng/toast';
import {SplitButtonModule} from 'primeng/splitbutton';
import { InfoUploadDocsComponent } from './info-upload-docs/info-upload-docs.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CarouselModule,
        NgxSpinnerModule,
        SidebarModule,
        DialogModule,
        CheckboxModule,
        CalendarModule,
        InputSwitchModule,
        MessagesModule,
        MessageModule,
        RatingModule,
        NgxPermissionsModule,
        AgmCoreModule,
        OverlayPanelModule,
        InfiniteScrollModule,
        ConfirmDialogModule,
        NgxStarRatingModule,
        ToastModule,
        SplitButtonModule
    ],
  declarations: [
    HeaderComponent,
    UserHeadersComponent,
    GoogleMapComponent,
    DashFooterComponent,
    ErrorComponent,
    FormComponent,
    AutocompleteComponent,
    GoogleLocationSelectorComponent,
    InfoUploadDocsComponent
  ],
  entryComponents: [
    FormComponent,
    InfoUploadDocsComponent
  ],
  providers: [
    MessageService,
    DialogService,
    ErrorHandlerService,
    AuthGuard,
    PublicGuard
  ],
  exports: [
    GoogleMapComponent,
    HeaderComponent,
    UserHeadersComponent,
    DashFooterComponent,
    ErrorComponent,
    FormComponent,
    AutocompleteComponent,
    GoogleLocationSelectorComponent,
    NgxSpinnerModule,
    SidebarModule,
    DialogModule,
    CheckboxModule,
    CalendarModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    AgmCoreModule,
    RatingModule,
    NgxPermissionsModule,
    NgxStarRatingModule
  ]
})
export class LogibidCommonModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LogibidCommonModule,
      providers: [
      ]
    };
  }
}
