import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
  selector: 'app-info-upload-docs',
  templateUrl: './info-upload-docs.component.html',
  styleUrls: ['./info-upload-docs.component.css']
})
export class InfoUploadDocsComponent implements OnInit {
 language = 'en';
  lan = localStorage.getItem('language') || 'en';
  languageKey = languageConstants[this.lan];
  languageConstants = languageConstants;
  data: string;
  heading: string;
  ExampleInfo: string;
  headingInfo: string;
  heading1: string;
  ExampleInfo1: string;
  headingInfo1: string;
  type = 1;

  constructor(private router: Router, private route: ActivatedRoute, private _dialog: DynamicDialogConfig, private _dynamicRef: DynamicDialogRef)  {
    const lang = localStorage.getItem('language');
    if(lang) {
      this.language = lang;
      this.languageKey = this.languageConstants[lang]
    }
    
  }

  ngOnInit() {
  this.type = this._dialog.data.type;
    if (this.type === 1) {
      this.ExampleInfo = this._dialog.data.example
      .split('.\n') 
      .map((sentence) => sentence.trim())
      .filter((sentence) => sentence !== ''); 
    this.headingInfo = this._dialog.data.headingInfo;
    this.heading = this._dialog.data.heading;
    } else {
      this.ExampleInfo = this._dialog.data.example
      .split('.\n') 
      .map((sentence) => sentence.trim())
      .filter((sentence) => sentence !== ''); 
    this.headingInfo = this._dialog.data.headingInfo;
    this.heading = this._dialog.data.heading;
    this.ExampleInfo1 = this._dialog.data.example1
    .split('.\n') 
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence !== ''); 
  this.headingInfo1 = this._dialog.data.headingInfo1;
  this.heading1 = this._dialog.data.heading1;
    }
  console.log('Processed ExampleInfo: ', this.ExampleInfo);
  }

  close(): void {
    this._dynamicRef.close();
  }

}
